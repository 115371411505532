<template>
<div>

  <div class="container-fluid wrapper-medium content-form">
    <div class="row">

      <div class="col-12 col-md-6" v-if="!userWithoutPhoneNumber">
        <figure class="img-gesture-round mb-2 ml-auto mr-auto ml-md-0">
          <img :src="imgGesture" alt="">
        </figure>
        <h1 class="mb-3 anim-load1">
          Email confirmed
        </h1>
        <h4 class="mb-3 anim-load2">Now set up 2-Step Verification with your phone</h4>
        <p class="lead anim-load3">
          We take security of your data seriously. With 2-Step Verification, you’ll protect your account with both your password and a code you'll receive to your phone every time you log in to TAYC-R.
        </p>
      </div>

      <div class="col-12 col-md-6" v-if="userWithoutPhoneNumber">
        <h1 class="mb-3 anim-load1">
          Finish your secure account setup
        </h1>
        <h4 class="mb-3 anim-load2">Set up 2-Step Verification with your phone</h4>
        <p class="lead anim-load3">
          We take security of your data seriously. With 2-Step Verification, you’ll protect your account with both your password and a code you'll receive to your phone every time you log in to TAYC-R.
        </p>
      </div>

      <div class="col-12 col-md-6">
        <div class="card mt-3 mt-md-0">
          <div class="card-body">
            <h3 class="text-center mb-3">Enter your phone number</h3>

            <b-form @submit.prevent="submitPhoneNumber" class="mb-3">
              <div class="form-group mt-3" ref="phone">
                <label for="phone">Your mobile phone number <button v-on:click="$showInfo('VerificationPhoneNumber')" class="btn form-info" type="button" aria-label="Show info">?</button></label>
                <phone-input-field v-model="form.phoneNumber" />
              </div>

              <b-alert variant="danger"
                dismissible
                :show="showDismissibleAlert"
                @dismissed="showDismissibleAlert=false">
                {{serverError}}
              </b-alert>
              <button type="submit" class="btn btn-warning btn-lg btn-block">Submit</button>
            </b-form>

          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />

</div>
</template>

<script>
import PhoneInputField from '@/components/modules/PhoneInputField.vue'

export default {
  name: 'SignupConfirm',
  components: {
    PhoneInputField,
  },
  data () {
    return {
      form: {
        phoneNumber: '',
        email: '',
        token: ''
      },
      serverError: '',
      showDismissibleAlert: false,
      token: null,
      userWithoutPhoneNumber: false,
      imgGesture: this.$randomGestureImage('thumb-up', 2)
    }
  },

  async created() {
    this.$store.state.loadingProgress = false
    this.$store.state.appSection = 'brochure'
    localStorage.removeItem('jwt')
    this.form.token = this.$route.query.code || ''
    this.form.email = this.$route.query.email || ''

    // Capture token saved in store if user has not added phone number yet and is signing in
    if (this.$store.state.signInProcessPhoneToAdd !== null) {
      this.userWithoutPhoneNumber = true
      this.form.token = this.$store.state.signInProcessPhoneToAdd
      console.log('Sign in process by user without phone number in progress', this.$store.state.jwtDecoded)
      this.form.email = this.$store.state.jwtDecoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']
    }
    //

    this.form.email = this.form.email.replace(/\s/g, "+")
    if (this.form.token === '' || this.form.email === '') {
      this.$router.push('/')
    } else {
      if (!this.userWithoutPhoneNumber) {
        await this.confirmEmail()
      }
    }
  },

  methods: {
    confirmEmail() {
      this.$store.state.loadingProgress = true
      return this.$http({
        method: 'post', 
        url: this.$api.AccountRegisterConfirmEmail,
        params: {
          email: this.form.email,
          code: this.form.token
        }
      })
      .then((response) => {
        console.log(response)
        if (response.data.token !== undefined && response.data.token !== null) {
          var token = response.data.token
          this.$store.state.jwt = token
          this.$store.state.jwtDecoded = this.$decodeToken(token)
          localStorage.setItem('jwt', token)
          this.$store.state.signInProcess = false
          this.$store.state.signInProcessPhoneToAdd = token
          this.alertMsgShow('Email confirmed', true);
        } else {
          this.alertMsgShow('You already confirmed your email. Please sign in.', false);
          this.$router.push('/sign-in')
        } 
      })
      .catch((error) => {
        console.error('Error', error)
        this.$router.push('/sign-in')
        this.alertMsgShow("Something went wrong or link expired", false);
      })
      .finally(() => {
        this.$store.state.loadingProgress = false
      })
    },

    submitPhoneNumber() {
      // Validate phone number
      if (this.form.phoneNumber?.valid !== true) {
        this.serverError = "Phone number is not valid";
        this.showDismissibleAlert = true;
      } else {
        console.log(this.form, this.token)
        this.showDismissibleAlert = false
        this.$store.state.loadingProgress = true
        this.$http({
          method: 'post', 
          url: this.$api.AccountRegisterAddPhoneNumber,
          params: {
            phoneNo: this.form.phoneNumber.number
          },
          headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
        })
        .then((response) => {
          console.log(response)
          this.$store.state.setupForm = this.form;
          this.$router.push('/verification')
        })
        .catch((error) => {
          console.error('Error', error)
          this.showError('Incorrect code. Please try again.')
        })
        .finally(() => {
          this.$store.state.loadingProgress = false
        })
      }
    },
  },
  watch: {
    form: {
      handler() {
        this.showDismissibleAlert = false;
      },
      deep: true,
    },
  }
}
</script>
